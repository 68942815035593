<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-sm p-2"
        style="height: 70vh"
      >
        <div class="mt-2">
          <img
            class="object-cover rounded"
            src="https://cdn.discordapp.com/attachments/1080058777971212370/1126421049043595304/POD_Banner_Shop_Now.png"
          />
        </div>
        <div class="mt-16">
          <h3 class="text-3xl font-sans font-bold">
            Join Craft Masters Club Now !
          </h3>

          <div class="" v-if="!getUser">
            <!-- <div
              class="inline-block w-full mt-5 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
            >
              <div
                class="flex inline-block w-full text-xl lg:text-lg font-bold p-5 border-none rounded-2xl text-gray-800 bg-gray-200 cursor-pointer hover:bg-gray-300 hover:text-gray-600"
              >
                <img
                  class=""
                  src="https://cdn.casetify.com/img/ui/icon-account-google.svg"
                />

                <div class="ml-3">Connect with Google</div>
              </div>
            </div> -->
            <div class="w-full">
              <GoogleLogin :callback="callback" class="mt-6 w-full" />
            </div>

            <!-- <div
              class="inline-block w-full mt-2 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
            >
              <div
                class="flex inline-block w-full text-xl lg:text-lg font-bold p-5 border-none rounded-2xl text-gray-800 bg-gray-200 cursor-pointer hover:bg-gray-300 hover:text-gray-600"
              >
                <img
                  class=""
                  src="https://cdn.casetify.com/img/ui/icon-account-apple.svg"
                />

                <div class="ml-3">Connect with Apple</div>
              </div>
            </div> -->
          </div>
          <div v-else>
            <div class="w-full flex justify-center mt-20">
              <div>
                <div class="flex justify-center mt-16 cursor-pointer">
                  <span class="h-20 w-20 flex justify-center items-center">
                    <span
                      class="animate-ping absolute inline-flex h-20 w-20 rounded-full bg-green-500 opacity-75"
                    ></span>
                    <div
                      class="relative inline-flex rounded-full h-20 w-20 bg-green-600 flex justify-center items-center text-3xl text-gray-200"
                    >
                      <i class="fa fa-check-square" style="font-size: 24px"></i>
                    </div>
                  </span>
                </div>

                <h3 class="text-3xl text-green-500 font-sans font-bold mt-5">
                  Login success
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickFooter class="mt-44" />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import { POOL_ADDRESS, VERSION } from "../../config";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products"
import nativeToast from "native-toast";

import {
  decodeCredential,
  googleAuthCodeLogin,
  googleTokenLogin,
  CallbackTypes,
  googleOneTap,
} from "vue3-google-login";

import user from "@/api/user";

export default {
  data() {
    return {};
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getAccess_token", "getUser"]),

  methods: {
    ...mapActions(["setUser", "setToken"]),
    callback(response) {
      const userData = decodeCredential(response.credential);

      this.login(userData);
    },

    async login(userData) {
      try {
        await user
          .login(userData)
          .then((response) => {
            this.setUser(response.data.user);

            this.setToken(response.data.user._id);

            setTimeout(() => {
              this.$router.push("/user/order");
            }, 1000);

            nativeToast({
              message: "Login success",
              position: "top",
              timeout: 3000,
              type: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
          });
      } catch (error) {
        nativeToast({
          message: error.message,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      }
    },
  },
  created() {},
};
</script>
